<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        Put the steps in order for the dilution of the titrant stock solution. Summarize these steps
        in your Original Proposal procedures.
      </p>

      <p class="mb-2" v-for="letter in letterOptions" :key="letter.inputName">
        {{ letter.text }}
        <v-select
          v-model="inputs[letter.inputName]"
          style="display: inline-block; max-width: 300px"
          class="my-1"
          :items="options"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'ChemUCI1LC_Q4S2_Q1',
  components: {StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
        input8: null,
      },
      options: [
        {
          text: 'Fill the volumetric flask to the calibration line on the neck with DI water.',
          value: 'option1',
        },
        {
          text: 'All the stock solution to flow from the pipet into the volumetric flask.',
          value: 'option2',
        },
        {
          text: 'Fill the volumetric pipet until the meniscus is inline with the etched line on the volumetric pipet.',
          value: 'option3',
        },
        {
          text: 'Prerinse the volumetric pipet with a small amount of stock solution.',
          value: 'option4',
        },
        {text: 'Cap and invert the volumetric flask to mix the new solution', value: 'option5'},
        {
          text: 'Obtain a volumetric pipet with a volume equal to the calculated value.',
          value: 'option6',
        },
        {text: 'Determine the volume of stock solution needed for dilution.', value: 'option7'},
        {
          text: 'Touch of any hanging drops before placing the pipet tip in the 25 mL volumetric flask.',
          value: 'option8',
        },
      ],
      letterOptions: [
        {text: 'Step 1: ', inputName: 'input1'},
        {text: 'Step 2: ', inputName: 'input2'},
        {text: 'Step 3: ', inputName: 'input3'},
        {text: 'Step 4: ', inputName: 'input4'},
        {text: 'Step 5: ', inputName: 'input5'},
        {text: 'Step 6: ', inputName: 'input6'},
        {text: 'Step 7: ', inputName: 'input7'},
        {text: 'Step 8: ', inputName: 'input8'},
      ],
    };
  },
};
</script>
